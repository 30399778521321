<template>
  <div>
    <Row
      :gutter="8"
      class="m-b-10"
    >
      <i-col span="12">
        <span class="text-14">{{ orderInfo.advertiserName}}，{{htmlToSchedule(demandStartDate,demandEndDate)}}</span>
      </i-col>
      <i-col
        span="12"
        class="text-right"
      >
        <slot name="header_extra">
        </slot>

      </i-col>
    </Row>
    <slot name="search_header">
      <div class="m-b-10">
        <RadioGroup
          type="button"
          size="small"
          v-model="selectedProductId"
          @on-change="changeProduct"
        >
          <Radio
            :label="product.productId"
            v-for="product in orderProductArray"
            :key="product.productId"
          >{{product.productName}}</Radio>
        </RadioGroup>
      </div>
    </slot>
    <Row
      :gutter="8"
      class="m-b-10"
    >
      <i-col span="4">
        <Select
          v-model="query.assetId"
          :clearable="true"
          size="small"
          placeholder="选择资产"
          @on-change="assetChange"
        >
          <Option
            v-for="item in assetArray"
            :key="'asset_'+item.assetId"
            :value="item.assetId"
          >{{item.assetName}}</Option>
        </Select>
      </i-col>
      <i-col span="4">
        <Select
          v-model="query.stationId"
          size="small"
          :clearable="true"
          placeholder="选择站点"
          @on-change="stataionChange"
        >
          <Option
            v-for="item in stationArray"
            :key="'asset_'+item.stationId"
            :value="item.stationId"
          >{{item.stationName}}</Option>
        </Select>
      </i-col>
      <i-col span="4">
        <Select
          v-model="query.position"
          size="small"
          :clearable="true"
          placeholder="选择位置"
        >
          <Option
            v-for="item in positionArray"
            :key="'asset_'+item.position"
            :value="item.position"
          >{{item.positionName}}</Option>
        </Select>
      </i-col>
      <i-col span="4">
        <Input
          v-model="query.keyword"
          size="small"
          placeholder="资源编号"
        />
      </i-col>
      <i-col span="2">
        <Button
          type="primary"
          icon="ios-search"
          size="small"
          @click="handleSearch"
        >搜索</Button>
      </i-col>
      <i-col span="6">
        <Button
          class="m-r-5"
          size="small"
          type="primary"
          :disabled="selectedResourceIds.length===0"
          @click="handleSetScreen"
        >设置画面</Button>
        <Button
          class="m-r-5"
          size="small"
          type="primary"
          v-if="basicData===2"
          :disabled="selectedResourceIds.length===0"
          @click="handleSetLK"
        >设置连刊</Button>
      </i-col>
    </Row>
    <!-- <div class="m-b-10">
      <label>未设置：</label>
      <Checkbox
        v-model="query.removeSetted"
        @on-change="handleSearch"
      ></Checkbox>
    </div> -->
    <div>
      <Table
        stripe
        size="small"
        :data="tableData.list"
        :columns="tableColumns"
        :loading="dataLoading"
        @on-selection-change="selectionChange"
      ></Table>
      <Row :gutter="8">
        <i-col span="12">
          <div
            class="m-t-5"
            style="line-height:25px;"
          >当前已选中<span style="color:#ff9900;padding:0 3px">{{selectedResourceIds.length}}</span>条</div>
        </i-col>
        <i-col span="12">
          <div class="paging_style">
            <Page
              size="small"
              :total="tableData.totalRow"
              :page-size="query.pageSize"
              show-total
              show-elevator
              show-sizer
              :page-size-opts="pagesizeSetting"
              @on-page-size-change='changePageSize'
              @on-change="changePage"
              :current="query.pageNumber"
            ></Page>
          </div>
        </i-col>
      </Row>

    </div>
    <Modal
      v-model="visiblePreview"
      width="800"
      :footer-hide="true"
    >
      <Row class="text-right m-b-5">
        <Button
          size="small"
          type="success"
          @click="viewBigImage"
        >查看原图</Button>
      </Row>
      <p
        slot="header"
        class="text-center"
      >{{fileName}}</p>
      <img-light-box
        ref="imgLightBox"
        :showModal="visiblePreview"
        :sortIndex.sync="sortIndex"
      ></img-light-box>
    </Modal>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { sysMixins } from '@/assets/mixins/sys'
import { commonMixins } from '@/assets/mixins/common'
// import { submitDemand } from '@/api/msp/demand'
import { setResourceLx } from '@/api/msp/demandV3'
import { GetCurrentSchedule } from '@/utils/dateFormat'
import { getResourceParameter } from '@/api/order/productdetail'

import ImgLightBox from '@/components/common/ImgVideoLightBox'
// import SettingDetail from './settting/SettingDetail'
export default {
  mixins: [sysMixins, commonMixins],
  components: {
    // SettingDetail
    ImgLightBox
  },
  data () {
    return {
      assetArray: [],
      stationArray: [],
      positionArray: [],
      query: {
        taskId: this.$store.state.installSetting.currentTaskId,
        productId: null,
        assetId: null,
        stationId: null,
        position: null,
        removeSetted: false,
        keyword: '',
        pageNumber: 1,
        pageSize: 15
      },
      // tableList: [],
      // total: 0,
      pagesizeSetting: [15, 50, 100, 200, 500],
      tableColumns: [],
      showTableColumns: new Map([
        ['printMedia', ['selection', 'resourceCode', 'stationName', 'positionName', 'side', 'currentScrren', 'installTimes', 'inspectTimes', 'operation']], // 平面媒体
        ['streamMedia', ['selection', 'assetName', 'resourceCode', 'stationName', 'positionName', 'launchTime', 'unitDuration', 'playTimes', 'tduration', 'currentScrren', 'installTimes', 'inspectTimes', 'operation']] // 流媒体
      ]),
      actionDate: '',
      visiblePreview: false,
      fileName: '',
      fileUrl: '',
      sortIndex: 0,
      dataLoading: false
    }
  },
  computed: {
    ...mapState({
      orderInfo: state => state.installSetting.orderInfo,
      orderProductArray: state => state.installSetting.orderProductArray,
      demandStartDate: state => state.installSetting.demandStartDate,
      demandEndDate: state => state.installSetting.demandEndDate,
      curTaskId: state => state.installSetting.currentTaskId,
      basicData: state => state.installSetting.basicData
    }),
    tableData: {
      get () {
        // return this.$store.state.installSetting.tableData
        const results = this.$store.state.installSetting.tableData
        if (results.list) {
          results.list.forEach(item => {
            if (this.$store.state.installSetting.selectedResourceIds.some(x => x === item.deviceId)) {
              item._checked = true
            }
          })
        }
        return results
      },
      set (val) {
        this.$store.commit('set_table_data', val)
      }
    },
    selectedProductId: {
      get () {
        return this.$store.state.installSetting.selectedProductId
      },
      set (val) {
        this.$store.commit('set_selected_productId', val)
      }
    },
    selectedResourceIds: {
      get () {
        return this.$store.state.installSetting.selectedResourceIds
      },
      set (val) {
        this.$store.commit('set_selected_resourceIds', val)
      }
    },
    tempSelectedResourceIds: { // 临时存储分页保留之前选中的resourceIds
      get () {
        return this.$store.state.installSetting.tempSelectedItem
      },
      set (val) {
        this.$store.commit('set_temp_selected_item', val)
      }
    },
    selectedTaskIds: {
      get () {
        return this.$store.state.installSetting.selectedTaskIds
      },
      set (val) {
        this.$store.commit('set_selected_taskIds', val)
      }
    }
  },
  created () {
    // 设置头部路由标签
    this.setLinkTagArray({ key: 'installSetScreen', value: '上刊画面设置' })
    this.setActivedTagName('installSetScreen')
    this.$store.commit('set_operating_type', 1)
  },
  destroyed () {
    // 组件注销时，清空当前组件产生非公用数据
    this.tableData = {}
    // this.$store.commit('set_resource_data', {})
    this.$store.commit('set_selected_taskIds', [])
    this.$store.commit('set_page_query', {})
    this.selectedResourceIds = []
    this.selectedProductId = null
    this.tempSelectedResourceIds = []
    this.selectedTaskIds = []
    // this.setMaxSide = 0
  },
  methods: {
    getTableColumns () {
      const tableColumnsData = {
        selection: { type: 'selection', width: 60, align: 'center', fixed: 'left' },
        assetName: { title: '资产', key: 'assetName', minWidth: 100 },
        resourceCode: { title: '资源编号', key: 'resourceCode', minWidth: 100 },
        stationName: { title: '所属站台', key: 'stationName', minWidth: 100 },
        positionName: { title: '位置', key: 'positionName', align: 'center', minWidth: 100 },
        unitDuration: { title: '规格(秒)', key: 'unitDuration', align: 'center', minWidth: 80 },
        playTimes: { title: '投放次数', key: 'playTimes', align: 'center', minWidth: 100 },
        tduration: { title: '总时长(秒)', key: 'tduration', align: 'center', minWidth: 100 },
        launchTime: {
          title: '投放时间',
          align: 'center',
          minWidth: 140,
          render: (h, params) => {
            return h('span', `${params.row.startTime} - ${params.row.endTime}`)
          }
        },
        currentScrren: {
          title: '当前素材',
          minWidth: 100,
          align: 'center',
          key: 'currentScrren',
          render: (h, params) => {
            if (params.row.currentFileList && params.row.currentFileList.length > 0) {
              if (params.row.currentFileList.some(x => (x.mimeType.includes('video') || x.mimeType.includes('audio')))) {
                return h('a', {
                  on: {
                    click: () => {
                      const postData = {
                        startIndex: 0,
                        imgList: params.row.currentFileList
                      }
                      this.sortIndex = 0
                      this.fileName = `${params.row.stationName} ${params.row.code}`
                      this.variableTitle = ''
                      this.$refs.imgLightBox.init(postData)
                      this.showCheckbox = false
                      this.visiblePreview = true
                    }
                  },
                  style: {
                    cursor: 'pointer'
                  }
                }, '查看素材')
              } else {
                return h('div', [
                  h(
                    'img',
                    {
                      domProps: {
                        src: params.row.currentFileList[0].fileKey + '?x-oss-process=image/resize,w_64'
                      },
                      on: {
                        click: () => {
                          const postData = {
                            startIndex: 0,
                            imgList: params.row.currentFileList
                          }
                          this.sortIndex = 0
                          this.fileName = `${params.row.stationName} ${params.row.code}`
                          this.variableTitle = ''
                          this.$refs.imgLightBox.init(postData)
                          this.showCheckbox = false
                          this.visiblePreview = true
                        }
                      },
                      style: {
                        width: '64px',
                        cursor: 'pointer'
                      }
                    }
                  )
                ])
              }
            } else {
              return h('span', '暂无素材')
            }
          }
        },
        side: { title: '画面数', key: 'side', align: 'center', minWidth: 100 },
        installTimes: {
          title: '上刊次数',
          align: 'center',
          minWidth: 60,
          render: (h, params) => {
            return h('span', params.row.times && params.row.times.installTimes ? params.row.times.installTimes : 0)
          }
        },
        inspectTimes: {
          title: '监播次数',
          align: 'center',
          minWidth: 60,
          render: (h, params) => {
            return h('span', params.row.times && params.row.times.inspectTimes ? params.row.times.inspectTimes : 0)
          }
        },
        // {
        //   title: '是否连刊',
        //   minWidth: 100,
        //   align: 'center',
        //   render: (h, params) => {
        //     return formatEnable(h, params.row.lx)
        //   }
        // }
        operation: {
          title: '操作',
          align: 'center',
          minWidth: 100,
          render: (h, params) => {
            return h('div', [
              h('a', {
                style: { marginRight: '5px' },
                on: {
                  click: () => {
                    this.$store.commit('set_current_resourceId', params.row.deviceId)
                    this.handelSetPublishingScreen()
                  }
                }
              }, '设置刊中画面')
            ])
          }
        }
      }

      const data = []
      let columnsKey = 'printMedia'
      if (this.query.productId === 28) {
        columnsKey = 'streamMedia'
      }

      const showColumns = this.showTableColumns.get(columnsKey)
      if (showColumns && showColumns.length) {
        showColumns.forEach(col => data.push(tableColumnsData[col]))
      }

      return data
    },
    changeProduct (name) {
      this.query.assetId = ''
      this.query.stationId = ''
      this.query.position = ''
      this.query.keyword = ''
      this.query.pageNumber = 1
      // this.query.removeSetted = false
    },
    htmlToSchedule (sDate, eDate) {
      return GetCurrentSchedule(sDate, eDate, false)
    },
    /**
     * 获取资产数据
     */
    getAssetArray () {
      const query = {
        orderId: this.orderInfo.id,
        productId: this.query.productId,
        startDate: this.demandStartDate,
        endDate: this.demandEndDate
      }
      getResourceParameter(query).then(res => {
        if (res && !res.errcode) {
          this.assetArray = res.assetList
        } else {
          this.assetArray = []
        }
      })
    },
    assetChange () {
      this.query.stationId = ''
      this.query.position = ''
      if (this.query.assetId) {
        const asset = this.assetArray.find(x => x.assetId === this.query.assetId)
        this.stationArray = asset ? asset.stationList : []
      } else {
        this.stationArray = []
      }
    },
    stataionChange () {
      this.query.position = ''
      if (this.query.stationId) {
        const station = this.stationArray.find(x => x.stationId === this.query.stationId)
        this.positionArray = station ? station.positionList : []
      } else {
        this.positionArray = []
      }
    },
    /**
     * 获取table数据
     */
    getResouceData () {
      // this.selectedResourceIds = []
      this.dataLoading = true
      this.tableData = {}
      this.$store.commit('set_selected_taskIds', [])
      this.$store.dispatch('getTaskDetailData', this.query).finally(() => { this.dataLoading = false })
    },
    handleSearch () {
      this.query.pageNumber = 1
      this.tempSelectedResourceIds = []
      this.selectedResourceIds = []
      this.selectedTaskIds = []
      this.getResouceData(this.query)
    },
    changePageSize (pagesize) {
      this.query.pageNumber = 1
      this.query.pageSize = pagesize
      this.tempSelectedResourceIds = []
      this.selectedResourceIds = []
      this.selectedTaskIds = []
      this.getResouceData()
    },
    changePage (curPage) {
      this.query.pageNumber = curPage
      this.getResouceData()
    },
    selectionChange (selections) {
      const tempSelections = []
      const curSelection = selections.map(x => {
        return { id: x.id, resourceId: x.resourceId, deviceId: x.deviceId, unitDuration: x.unitDuration, playTimes: x.playTimes }
      })
      this.handelPageSelections(this.tempSelectedResourceIds, curSelection, this.query.pageNumber)
      // 最终选中的所有taskItemIds
      this.selectedResourceIds = []
      this.selectedTaskIds = []
      this.tempSelectedResourceIds.forEach(item => {
        this.selectedResourceIds = this.selectedResourceIds.concat(item.existSelections.map(x => x.deviceId))
        this.selectedTaskIds = this.selectedTaskIds.concat(item.existSelections.map(x => x.id))
        // PIS屏获取所选项规格和投放次数
        if ([28].includes(this.query.productId)) {
          item.existSelections.forEach(child => {
            if (!tempSelections.some(x => x.unitDuration === child.unitDuration && x.playTimes === child.playTimes)) {
              tempSelections.push({ unitDuration: child.unitDuration, playTimes: child.playTimes })
            }
          })
        }
      })

      // PIS屏只能相同规格和相同次数进行批量操作
      if ([28].includes(this.query.productId)) {
        if (tempSelections.length > 1) {
          this.selectedResourceIds = []
          this.selectedTaskIds = []
          this.$Notice.warning({ desc: '请选择规格相同且投放次数相同的资源进行批量操作' })
        } else {
          this.$store.commit('set_selected_stmedia_attr', tempSelections[0])
        }
      }
    },
    /**
     * 设置刊中画面
     */
    handelSetPublishingScreen () {
      this.setLeftComponent('SetPublishingPicSource')
      this.setShowLeftBlock(true)
    },
    viewBigImage () {
      this.$refs.imgLightBox.viewBigImg()
    },
    handleSetScreen () {
      if (this.selectedTaskIds.length > 0) {
        this.setLeftComponent('SetScreen')
        this.setShowLeftBlock(true)
      }
    },
    /**
     * 设置连刊
     */
    handleSetLK () {
      this.$Modal.confirm({
        title: '操作提示',
        content: '确定将所选数据设置连刊？',
        onOk: () => {
          const params = {
            taskId: this.curTaskId,
            // productId: this.selectedProductId,
            deviceIds: JSON.stringify(this.selectedResourceIds)
          }
          setResourceLx(params).then(res => {
            if (res && res.errcode === 0) {
              this.selectedResourceIds = []
              this.selectedTaskIds = []
              // 调用列表数据获取函数
              this.$store.dispatch('getTaskDetailData')
              // 调用右侧数据统计函数
              this.$store.dispatch('getOrderProductArray')
              this.$Notice.success({ desc: '操作成功' })
            }
          })
        }
      })
    }
  },
  watch: {
    selectedProductId (val) {
      this.query.productId = val
      this.tableColumns = this.getTableColumns()
      this.getAssetArray()
      this.getResouceData()
    }
  }
}
</script>
